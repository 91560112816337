// RC Select

@-webkit-keyframes select-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes select-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rc-select {

  display: inline-block;
  position: relative;
  vertical-align: middle;
  color: $body-color;
  line-height: 34px;
}

.rc-select ul,
.rc-select li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rc-select > ul > li > a {
  padding: 0;
  background-color: $white;
}

.rc-select-arrow {
  height: 30px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  outline: none;
}

.rc-select-arrow .rc-select-arrow-loading {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-top: 6px;
  margin-left: -4px;
}

.rc-select-arrow .rc-select-arrow-loading:after {
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin: 2px;
  border-radius: 50%;
  border: 2px solid $gray-500;
  border-color: $gray-500 transparent $gray-500 transparent;
  -webkit-animation: select-ring 1.2s linear infinite;
  animation: select-ring 1.2s linear infinite;
}

.rc-select-arrow .rc-select-arrow-icon {
  border-color: $gray-500 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  width: 0;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.rc-select:not(.rc-select-no-arrow) .rc-select-selection--multiple .rc-select-selection__clear,
.rc-select-loading .rc-select-selection--multiple .rc-select-selection__clear {
  right: 20px;
}

.rc-select-selection {
  outline: none;
  user-select: none;

  display: block;
  background-color: $white;
  @include border-radius($btn-border-radius);
  border: 1px solid $gray-500;
  transition: $transition-base;
}

.rc-select-selection__placeholder {
  position: absolute;
  top: 0;
  color: $gray-400;
}

.rc-select-selection__clear {
  font-weight: bold;
  position: absolute;
  line-height: 34px;
}

.rc-select-selection__clear-icon {
  font-style: normal;
}

.rc-select-focused .rc-select-selection {
  border-color: $primary;
}

.rc-select-enabled .rc-select-selection:hover {
  border-color: $primary;
}

.rc-select-enabled .rc-select-selection:active {
  border-color: $primary;
}

.rc-select-selection--single {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  position: relative;
}

.rc-select-selection--single .rc-select-selection-selected-value {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
}

.rc-select-selection--single .rc-select-selection__rendered {
  height: 34px;
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
  margin-right: 30px;
  line-height: 34px;
}

.rc-select-selection--single .rc-select-selection__clear {
  top: 0;
  right: 20px;
}

.rc-select-disabled {
  color: $gray-700;
  cursor: not-allowed;
}

.rc-select-disabled .rc-select-selection--single,
.rc-select-disabled .rc-select-selection__choice__remove {
  cursor: not-allowed;
  color: $gray-700;
}

.rc-select-disabled .rc-select-selection--single:hover,
.rc-select-disabled .rc-select-selection__choice__remove:hover {
  cursor: not-allowed;
  color: $gray-700;
}

.rc-select-search__field__wrap {
  display: inline-block;
}

.rc-select-search__field__placeholder {
  position: absolute;
  top: 0;
  left: 3px;
  color: $gray-400;
}

.rc-select-search--inline {
  width: 100%;
}

.rc-select-search--inline .rc-select-search__field__wrap {
  width: 100%;
}

.rc-select-search--inline .rc-select-search__field {
  border: none;
  font-size: 100%;
  background: transparent;
  outline: 0;
  width: 100%;
}

.rc-select-search--inline .rc-select-search__field::-ms-clear {
  display: none;
}

.rc-select-search--inline .rc-select-search__field__mirror {
  position: absolute;
  top: -999px;
  left: 0;
  white-space: pre;
}

.rc-select-search--inline > i {
  float: right;
}

.rc-select-enabled.rc-select-selection--multiple {
  cursor: text;
}

.rc-select-selection--multiple {
  min-height: 34px;
}

.rc-select-selection--multiple .rc-select-search--inline {
  float: left;
  width: auto;
}

.rc-select-selection--multiple .rc-select-search--inline .rc-select-search__field {
  width: 0.75em;
}

.rc-select-selection--multiple .rc-select-search--inline .rc-select-search__field__wrap {
  width: auto;
}

.rc-select-selection--multiple .rc-select-search__field__placeholder {
  top: 5px;
  left: 8px;
}

.rc-select-selection--multiple .rc-select-selection__rendered {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
  padding-bottom: 2px;
}

.rc-select-selection--multiple .rc-select-selection__rendered .rc-select-selection__choice {
  margin-top: 4px;
  line-height: 20px;
}

.rc-select-selection--multiple .rc-select-selection__clear {
  top: 1px;
  right: 8px;
}

.rc-select-enabled .rc-select-selection__choice {
  cursor: default;
}

.rc-select-enabled .rc-select-selection__choice:hover .rc-select-selection__choice__remove {
  opacity: 1;
  transform: scale(1);
}

.rc-select-enabled .rc-select-selection__choice:hover .rc-select-selection__choice__content {
  margin-left: -8px;
  margin-right: 8px;
}

.rc-select-enabled .rc-select-selection__choice__disabled {
  cursor: not-allowed;
}

.rc-select-enabled .rc-select-selection__choice__disabled:hover .rc-select-selection__choice__content {
  margin-left: 0;
  margin-right: 0;
}

.rc-select .rc-select-selection__choice {
  background-color: $gray-200;
  border-radius: 4px;
  float: left;
  padding: 0 15px;
  margin-right: 4px;
  position: relative;
  overflow: hidden;
  transition: padding 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.rc-select .rc-select-selection__choice__content {
  margin-left: 0;
  margin-right: 0;
  transition: margin 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.rc-select .rc-select-selection__choice-zoom-enter,
.rc-select .rc-select-selection__choice-zoom-appear,
.rc-select .rc-select-selection__choice-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.rc-select .rc-select-selection__choice-zoom-leave {
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.rc-select .rc-select-selection__choice-zoom-enter.rc-select-selection__choice-zoom-enter-active,
.rc-select .rc-select-selection__choice-zoom-appear.rc-select-selection__choice-zoom-appear-active {
  animation-play-state: running;
  animation-name: rcSelectChoiceZoomIn;
}

.rc-select .rc-select-selection__choice-zoom-leave.rc-select-selection__choice-zoom-leave-active {
  animation-play-state: running;
  animation-name: rcSelectChoiceZoomOut;
}

@-webkit-keyframes rcSelectChoiceZoomIn {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rcSelectChoiceZoomIn {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes rcSelectChoiceZoomOut {
  to {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes rcSelectChoiceZoomOut {
  to {
    transform: scale(0);
    opacity: 0;
  }
}

.rc-select .rc-select-selection__choice__remove {
  color: $danger;
  cursor: pointer;
  font-weight: bold;
  padding: 0 0 0 8px;
  position: absolute;
  opacity: 0;
  transform: scale(0);
  top: 0;
  right: 2px;
  transition: opacity 0.3s, transform 0.3s;
}

.rc-select .rc-select-selection__choice__remove-icon {
  font-style: normal;
}

.rc-select .rc-select-selection__choice__remove:hover {
  color: $danger;
}

.rc-select-dropdown {
  background-color: white;
  @include border-radius($dropdown-border-radius);
  @include box-shadow($dropdown-box-shadow);
  background-color: $dropdown-bg;
  padding: $dropdown-padding-y;
  box-shadow: $dropdown-box-shadow;

  z-index: 100;
  left: -9999px;
  top: -9999px;
  position: absolute;
  outline: none;
}

.rc-select-dropdown:empty,
.rc-select-dropdown-hidden {
  display: none;
}

.rc-select-dropdown-menu {
  outline: none;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 9999;
}

.rc-select-dropdown-menu > li {
  margin: 0;
  padding: 0;
}

.rc-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}

.rc-select-dropdown-menu-item-group-list > li.rc-select-menu-item {
  padding-left: 20px;
}

.rc-select-dropdown-menu-item-group-title {
  color: $gray-700;
  text-transform: capitalize;
  line-height: 1.5;
  padding: 8px 10px;
  border-bottom: 1px solid $gray-400;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
}

li.rc-select-dropdown-menu-item {
  margin: 0;
  position: relative;
  display: block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  font-weight: normal;
  color: $body-color;
  white-space: nowrap;
  margin: 1px 0;
  @include border-radius($border-radius-sm);
  transition: $transition-base;
}

li.rc-select-dropdown-menu-item-disabled {
  color: $gray-700;
  cursor: not-allowed;
}

li.rc-select-dropdown-menu-item-selected {
  color: $body-color;
  background-color: $gray-500;
}

li.rc-select-dropdown-menu-item-active {
  background-color: $primary;
  color: white;
  cursor: pointer;
}

li.rc-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  background-color: $gray-300;
  line-height: 0;
}

.rc-select-dropdown-slide-up-enter,
.rc-select-dropdown-slide-up-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}

.rc-select-dropdown-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}

.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-bottomLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpIn;
  animation-play-state: running;
}

.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpOut;
  animation-play-state: running;
}

.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-topLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownIn;
  animation-play-state: running;
}

.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownOut;
  animation-play-state: running;
}

@-webkit-keyframes rcSelectDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}

@keyframes rcSelectDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}

@-webkit-keyframes rcSelectDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}

@keyframes rcSelectDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}

@-webkit-keyframes rcSelectDropdownSlideDownIn {
  0% {
    opacity: 0;
    transform-origin: 0% 100%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 100%;
    transform: scaleY(1);
  }
}

@keyframes rcSelectDropdownSlideDownIn {
  0% {
    opacity: 0;
    transform-origin: 0% 100%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 100%;
    transform: scaleY(1);
  }
}

@-webkit-keyframes rcSelectDropdownSlideDownOut {
  0% {
    opacity: 1;
    transform-origin: 0% 100%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 100%;
    transform: scaleY(0);
  }
}

@keyframes rcSelectDropdownSlideDownOut {
  0% {
    opacity: 1;
    transform-origin: 0% 100%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 100%;
    transform: scaleY(0);
  }
}

.rc-select-open .rc-select-arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.register-box  .rc-select-selection--single {
  height: calc(1.5em + 1.06rem + 2px);
  padding-top: 4px;
  padding-left: 10px;
  .rc-select-arrow{
    margin-top: 4px;
  }
  .rc-select-selection__clear{
    margin-top: 4px;
  }
}