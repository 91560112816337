.react-bootstrap-table table {
    table-layout: auto;
}

.expand-cell-header {
    width: 20px;
}

.react-bootstrap-table table.sticky-table > thead > tr:nth-child(1) > th:nth-child(1) { 
    background-color:#f4f5fd; 
    position: sticky; 
    position: -webkit-sticky; 
    left:0; 
    z-index:1; 
}

.react-bootstrap-table table.sticky-table > tbody > tr > td:nth-child(1) { 
    background-color: #fff; 
    position: sticky; 
    position: -webkit-sticky;
    left:0;
    z-index:1; 
}